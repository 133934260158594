'use client';
import environments from '@src/configs/environments';

export function DRepIdScript() {
  console.log('environments', environments);
  if (environments.enableUmami)
    return (
      <script
        defer
        src="/script.js"
        data-website-id={environments.publicUmamiId}
        data-host-url="/"
      ></script>
    );
}
